import * as React from 'react'
import { VictoryTheme, VictoryChart, VictoryAxis, VictoryLine } from 'victory'
import { visitors } from '../../../content/charts'

const Visitors = () => (
  <VictoryChart theme={VictoryTheme.material} padding={{ left: 40, bottom: 40, top: 40, right: 16 }}>
    <VictoryAxis
      dependentAxis
      tickFormat={(tick) => `${tick}`}
      tickCount={8}
      style={{
        tickLabels: { fontSize: 8 },
        ticks: { strokeWidth: 0 },
        axis: { strokeWidth: 0 },
        grid: { stroke: (tick) => (tick === 0 ? 'transparent' : '#b8c2cc'), strokeWidth: 2 },
      }}
    />
    <VictoryAxis
      tickFormat={(tick, i) => {
        if (i % 5 === 0) {
          return `${tick}`
        }
      }}
      style={{
        tickLabels: { fontSize: 6 },
        axis: { stroke: 'black', strokeWidth: 1 },
        ticks: {
          stroke: 'black',
          strokeWidth: 1,
          size: (tick, index) => (index % 5 === 0 ? 10 : 5),
        },
      }}
      scale="time"
    />
    <VictoryLine data={visitors} style={{ data: { stroke: '#4361cc' } }} domain={{ y: [0, 100000] }} />
  </VictoryChart>
)

export default Visitors
