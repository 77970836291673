import * as React from 'react'
import { VictoryPie, VictoryTheme, VictoryLegend, VictoryContainer } from 'victory'
import { countries } from '../../../content/charts'
import { blueGreen } from '../../styles/colors'

const scale = [...blueGreen].reverse()

const Countries = () => (
  <VictoryContainer width={260} height={250}>
    <VictoryPie
      standalone={false}
      labels={({ datum }) => `${datum.y}%`}
      labelRadius={85}
      data={countries}
      width={260}
      height={260}
      theme={VictoryTheme.material}
      colorScale={scale}
      innerRadius={30}
      origin={{ y: 150 }}
      style={{ labels: { fontSize: 7, fill: '#505e6a' } }}
    />
    <VictoryLegend
      standalone={false}
      centerTitle
      theme={VictoryTheme.material}
      data={countries.map(({ x, y }) => ({ name: x }))}
      orientation="horizontal"
      style={{ title: { fontWeight: 'bold' }, labels: { fontSize: 8 } }}
      colorScale={scale}
      itemsPerRow={4}
    />
  </VictoryContainer>
)

export default Countries
