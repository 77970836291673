import { Fade } from "react-reveal";
import { Affinity, Countries, Demographics, Visitors } from "../../../../src/components/charts";
import { Facts, Button, Background, Publisher, Contact, CTA, FirstCTA, Icon, TwoCharts } from "../../../../src/components";
import Footer from "../../../../src/elements/Footer";
import * as React from 'react';
export default {
  Fade,
  Affinity,
  Countries,
  Demographics,
  Visitors,
  Facts,
  Button,
  Background,
  Publisher,
  Contact,
  CTA,
  FirstCTA,
  Icon,
  TwoCharts,
  Footer,
  React
};