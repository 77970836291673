import * as React from 'react'
import { Global } from '@emotion/react'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'

const { theme } = resolveConfig(tailwindConfig)
const { colors } = theme

const Layout = ({ children }) => (
  <>
    <Global
      styles={() => ({
        '*': {
          boxSizing: `inherit`,
        },
        '::selection': {
          color: 'white',
          backgroundColor: colors.yellow['400'],
        },
        html: {
          color: colors.gray['700'],
          background: colors.gray['100'],
          border: 0,
          margin: 0,
          fontSize: '18px',
        },
        'h1, h2, h3, h4, h5, h6': {
          color: colors.gray['900'],
        },
        body: {
          border: 0,
          margin: 0,
          padding: 0,
        },
        button: {
          border: 'none',
        },
        a: {
          transition: 'all 0.3s ease-in-out',
        },
      })}
    />
    {children}
  </>
)

export default Layout
