import tw, { styled } from 'twin.macro'

export const FirstCTA = styled.div`
  ${tw`text-center`};
  span {
    ${tw`mx-4 my-4 font-sans text-lg inline-block`};
  }
`

export const Contact = styled.div`
  ${tw`text-center mb-12`};
  h3 {
    ${tw`font-sans font-normal text-lg md:text-2xl`};
  }
`

export const CTA = styled.div`
  ${tw`inline-flex items-center`};
  a {
    ${tw`no-underline md:ml-4 font-sans text-black hover:text-yellow-600 text-xl md:text-2xl font-bold`};
  }
`

export const Icon = tw.div`rounded-full p-4 bg-yellow-300 hidden md:inline-flex`

export const TwoCharts = styled.div`
  ${tw`flex flex-wrap`};
  > div {
    flex: 1 0 50%;
  }
  @media (max-width: 576px) {
    > div {
      flex: 1 0 100%;
      margin-bottom: 2rem;
    }
  }
`
