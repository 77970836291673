import tw, { styled } from 'twin.macro'

const Highlight = tw.b`font-sans text-lg md:text-xl text-gray-800 font-semibold`

const Text = styled.p`
  ${tw`font-sans text-base md:text-lg`};
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
`

const Quote = styled.blockquote`
  ${tw`font-sans relative my-12 pt-2`};
  p {
    ${tw`text-blue-800 mx-0 px-6 italic text-lg md:text-xl`};
  }
  cite {
    ${tw`block text-left text-blue-800 px-6 text-base mt-4`};
  }
  &:before {
    position: absolute;
    top: -2.5rem;
    color: #e2ba5a;
    font-style: normal;
    left: -1.5rem;
    content: '\\201C';
    font-family: arial, sans-serif;
    width: 5.6rem;
    height: 5.6rem;
    font-size: 8rem;
    line-height: 1;
    text-align: center;
    z-index: -1;
  }
`

export { Highlight, Text, Quote }
