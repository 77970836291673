import * as React from 'react'
import { VictoryTheme, VictoryChart, VictoryBar, VictoryAxis } from 'victory'
import { affinity } from '../../../content/charts'
import { blueGreen } from '../../styles/colors'

const scale = [...blueGreen].reverse()

const Affinity = () => (
  <VictoryChart
    theme={VictoryTheme.material}
    domainPadding={25}
    padding={{ left: 25, bottom: 30, top: 10 }}
    height={200}
  >
    <VictoryAxis tickFormat={(tick) => `${tick}`} style={{ tickLabels: { fontSize: 8 } }} />
    <VictoryAxis dependentAxis tickFormat={(tick) => `${Math.round(tick)}%`} style={{ tickLabels: { fontSize: 8 } }} />
    <VictoryBar
      data={affinity}
      style={{
        data: {
          fill: (d) => scale[d.index],
        },
      }}
    />
  </VictoryChart>
)

export default Affinity
