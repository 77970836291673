module.exports = {
  theme: {
    extend: {
      colors: {
        'epvp-color': '#e2ba5a',
      },
      spacing: {
        '2px': '2px',
      },
    },
  },
}
