export const blueGreen = [
  '#CEE6CA',
  '#91D1CE',
  '#8ACECE',
  '#3ea4ce',
  '#3575b0',
  '#325189',
  '#27458a',
  '#1b287c',
  '#131a4b',
]
export const blue = ['#9dccf9', '#42a0f0', '#1e7cd8', '#155db8', '#0d479b', '#0d3a73']
