import tw, { styled } from 'twin.macro'

const Button = styled.a`
  ${tw`bg-yellow-400 font-sans cursor-pointer text-black text-sm py-3 px-6 md:px-8 rounded-full no-underline focus:outline-none focus:ring inline-block uppercase tracking-wide font-bold`};
  &:hover {
    animation: pulse-shadow 3s infinite;
  }
  @keyframes pulse-shadow {
    0% {
      box-shadow: 0 0 0 0 rgba(226, 186, 90, 1);
    }
    50% {
      box-shadow: 0 0 8px 12px rgba(226, 186, 90, 0);
    }
    80% {
      box-shadow: 0 0 0 0 rgba(226, 186, 90, 0);
    }
  }
`

export default Button
