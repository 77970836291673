import * as React from 'react'
import { Fade } from 'react-reveal'
import tw, { styled } from 'twin.macro'
import { graphql, useStaticQuery } from 'gatsby'

const Wrapper = styled.div`
  ${tw`relative mb-24`};
  &:after {
    content: '';
    position: absolute;
    width: 5px;
    background-color: #bcdefa;
    top: 10px;
    bottom: 0;
    left: 46.5px;
    z-index: -1;
    margin-top: 1rem;
    margin-bottom: -2rem;
  }
  @media (max-width: 768px) {
    &:after {
      left: 27px;
    }
  }
`

const BGItem = styled.div`
  ${tw`font-sans my-12`};
  article {
    ${tw`flex items-center`};
  }
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  div:last-of-type {
    ${tw`text-xs md:text-sm bg-white px-4 py-4 rounded shadow`};
  }
`

const Info = tw.div`mr-4 md:mr-6 text-xs md:text-base inline-block bg-blue-200 text-blue-700 py-1 md:py-2 px-3 md:px-6 rounded-full font-bold self-center`

const Background = () => {
  const { bg } = useStaticQuery(graphql`
    query Facts {
      bg: allBackgroundYaml {
        edges {
          node {
            year
            text
            id
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      {bg.edges.map((info) => (
        <BGItem key={info.node.id}>
          <Fade up>
            <article>
              <Info>{info.node.year}</Info>
              <div dangerouslySetInnerHTML={{ __html: info.node.text }} />
            </article>
          </Fade>
        </BGItem>
      ))}
    </Wrapper>
  )
}

export default Background
