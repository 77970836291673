import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import tw, { styled } from 'twin.macro'
import Container from './Container'

const Wrapper = tw.article`flex items-center`

const Icon = tw.div`rounded-full p-4 inline-flex bg-blue-200`

const Text = tw.div`font-sans ml-4`

const Title = styled.div`
  ${tw`text-xs md:text-sm text-gray-700`};
  min-width: 130px;
`

const Number = tw.div`text-base md:text-xl font-bold text-gray-800`

const Layout = styled(Container)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    article {
      justify-content: center;
    }
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 768px) {
    article:nth-of-type(3n + 2) {
      justify-content: center;
    }
    article:nth-of-type(3n + 3) {
      justify-content: flex-end;
    }
  }
`

const Fact = ({ icon, title, count }) => (
  <Wrapper>
    <Icon aria-hidden focusable="false">
      <img src={`/icons/${icon}.svg`} alt={`${title} - Icon`} />
    </Icon>
    <Text>
      <Title>{title}</Title>
      <Number>{count}</Number>
    </Text>
  </Wrapper>
)

const Facts = () => {
  const { facts } = useStaticQuery(graphql`
    query FactsQuery {
      facts: allFactsYaml {
        edges {
          node {
            title
            count
            icon
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {facts.edges.map((fact) => (
        <Fact key={fact.node.title} icon={fact.node.icon} title={fact.node.title} count={fact.node.count} />
      ))}
    </Layout>
  )
}

export default Facts
