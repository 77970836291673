import * as React from 'react'
import tw, { styled } from 'twin.macro'

const Wrapper = styled.footer`
  ${tw`font-sans text-center mt-24 mb-8 text-gray-700 px-6`};
  a {
    ${tw`no-underline text-blue-600`};
  }
`

const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <Wrapper>
      &copy; 2003 - {year} by <a href="https://www.elitepvpers.com">elitepvpers.com</a>.<br />
      All rights reserved. <a href="https://www.elitepvpers.com/forum/sendmessage.php">Legal Notice</a>.
    </Wrapper>
  )
}

export default Footer
