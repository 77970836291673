import * as React from 'react'
import tw, { styled } from 'twin.macro'
import kebabCase from 'lodash/kebabCase'

const Wrapper = styled.div`
  ${tw`border-b-2 border-gray-300 mb-12`};
  border-bottom-style: solid;
`

const BigTitle = styled.h2`
  ${tw`inline-block font-sans text-sm md:text-base font-bold uppercase tracking-wide text-black border-b-2 border-epvp-color -mb-2px pb-2`};
  border-bottom-style: solid;
`

export const Heading = ({ children }) => (
  <Wrapper>
    <BigTitle id={kebabCase(children)}>{children}</BigTitle>
  </Wrapper>
)

const Title = tw.h3`font-sans text-3xl md:text-4xl font-bold text-gray-800 mb-4 pt-6 md:pt-12`

export const SubHeading = ({ children }) => <Title id={kebabCase(children)}>{children}</Title>
