import * as React from 'react'
import { VictoryPie, VictoryTheme, VictoryLegend, VictoryContainer } from 'victory'
import { age, gender } from '../../../content/charts'
import { blue } from '../../styles/colors'

const colorScale = blue

const Demographics = () => (
  <>
    <VictoryContainer width={235} height={230}>
      <VictoryPie
        standalone={false}
        labels={({ datum }) => `${datum.y}%`}
        labelRadius={80}
        data={age}
        width={250}
        height={250}
        theme={VictoryTheme.material}
        origin={{ x: 120, y: 150 }}
        colorScale={colorScale}
        innerRadius={30}
        style={{ labels: { fontSize: 9, fill: '#505e6a' } }}
      />
      <VictoryLegend
        standalone={false}
        title="Age"
        centerTitle
        theme={VictoryTheme.material}
        data={age.map(({ x, y }) => ({ name: x }))}
        orientation="horizontal"
        style={{ title: { fontWeight: 'bold' } }}
        colorScale={colorScale}
      />
    </VictoryContainer>
    <VictoryContainer width={235} height={230}>
      <VictoryPie
        standalone={false}
        labels={() => null}
        data={gender}
        width={250}
        height={250}
        theme={VictoryTheme.material}
        origin={{ x: 120, y: 150 }}
        colorScale={colorScale}
      />
      <VictoryLegend
        standalone={false}
        title="Gender"
        centerTitle
        theme={VictoryTheme.material}
        data={gender.map(({ x, y }) => ({ name: x }))}
        orientation="horizontal"
        style={{ title: { fontWeight: 'bold' } }}
        colorScale={colorScale}
        x={55}
      />
    </VictoryContainer>
  </>
)

export default Demographics
