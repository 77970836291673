export const age = [
  { x: '18-24', y: 66 },
  { x: '25-34', y: 27 },
  { x: '35-44', y: 5 },
  { x: '45+', y: 2 },
]

export const gender = [
  { x: 'Male', y: 91 },
  { x: 'Female', y: 9 },
]
