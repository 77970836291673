export const countries = [
  { x: 'Germany', y: 27 },
  { x: 'United States', y: 9 },
  { x: 'Ukraine', y: 7 },
  { x: 'France', y: 5 },
  { x: 'Philippines', y: 5 },
  { x: 'Netherlands', y: 4 },
  { x: 'Turkey', y: 3 },
  { x: 'Egypt', y: 3 },
  { x: 'Other', y: 37 },
]
