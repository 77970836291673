const dates = [
  '01.08.2023',
  '02.08.2023',
  '03.08.2023',
  '04.08.2023',
  '05.08.2023',
  '06.08.2023',
  '07.08.2023',
  '08.08.2023',
  '09.08.2023',
  '10.08.2023',
  '11.08.2023',
  '12.08.2023',
  '13.08.2023',
  '14.08.2023',
  '15.08.2023',
  '16.08.2023',
  '17.08.2023',
  '18.08.2023',
  '19.08.2023',
  '20.08.2023',
  '21.08.2023',
  '22.08.2023',
  '23.08.2023',
  '24.08.2023',
  '25.08.2023',
  '26.08.2023',
  '27.08.2023',
  '28.08.2023',
  '29.08.2023',
  '30.08.2023',
  '31.08.2023',
]

const visits = [
  82820, 85335, 80115, 87583, 83070, 80508, 83964, 86376, 89037, 82377, 81586, 84283, 84072, 86956, 88685, 92086, 87915,
  88578, 90460, 85800, 80200, 85528, 87073, 84469, 84717, 85743, 84938, 86097, 85674, 87641, 84047,
]

export const visitors = dates.map((v, i) => ({ x: v, y: visits[i] }))
